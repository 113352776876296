import React from "react";
import { Spin } from 'antd';
import './index.less'

const Loading = ()=> {
    return (
      <div className='loading'>
         <Spin />
      </div>
    );
};

export default Loading;
