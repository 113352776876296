import React, { createContext } from "react";
import { MobXProviderContext } from 'mobx-react'

export interface AppContextType {
  getTagColor: () => string,
  tagColor: Array<string>,
  [key: string]: any
}

export const useStores = () => {
  return React.useContext(MobXProviderContext)
}

// @ts-ignore
export const AppContext = createContext();


