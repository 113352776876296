import {getLocalStorage, typeObj} from "../utils";
import routers from "./index";
import {onRouteBeforeRule, RouteObjectRule} from "./guard";

//根据路径获取路由
const checkAuth = (routers: Array<RouteObjectRule>, path: string): typeObj | null => {
  for (const data of routers) {
    if (data.path === path) return data;
    if (data.children) {
      const res = checkAuth(data.children, path);
      if (res) return res;
    }
  }
  return null
};

// 单独的页面需要设置权限等， 根据路径，获取路由的详情
const checkRouterAuth = (path: string) => checkAuth(routers, path);

//全局路由守卫
const onRouteBefore: onRouteBeforeRule = (meta, to) => {
  const {auth, title} = meta;
  if (title) {    // 动态修改页面title
    document.title = title || '统一认证';
  }
  // return to;
  // token权限验证
  return (auth && !getLocalStorage('access_token')) ? '/login' : to;
};

export default routers;

export {
  onRouteBefore,
  checkRouterAuth,
}
