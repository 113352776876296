import http from '../utils/http'

export function getArticleList(data) {
  return http('/api/article/getList', 'get', data)
}

export function getArticleDetail(data) {
  return http('/api/article/doDetail', 'post', data)
}

export function setArticleShowcont(data) {
  return http('/api/article/showcont/doEdit', 'post', data)
}

export function getBlogInit() {
  return http('/api/blog/init', 'get')
}

export function getBlogMessageList() {
  return http('/api/blog/message/list', 'get')
}

export function setBlogMessageAdd(data) {
  return http('/api/blog/message/add', 'post', data)
}
