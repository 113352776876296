// @ts-nocheck
/* eslint-disable */
import React from 'react';
const modulesFiles = require.context('./modules', true, /\.ts$/);

const modules = modulesFiles.keys().reduce((modules: any, modulePath: any) => {
    let moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
    moduleName.indexOf('/') !== -1 ? (moduleName = moduleName.split('/')[1]) : '';
    const value = modulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});

export const storesContext = React.createContext({...modules});
export const appStores = () => React.useContext(storesContext);
