import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';

import App from '@/App'

import 'antd/dist/reset.css'
import './style/animate.less'
import './style/index.less'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)


root.render(
  <ConfigProvider locale={zhCN}>
    <Router>
      <App />
    </Router>
  </ConfigProvider>
)

