import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {Layout, Menu} from 'antd';
import type {MenuProps} from 'antd';
import RouterGuard from './router/guard'
import routers, {onRouteBefore} from './router/auth'
// @ts-ignore
import ReactCanvasNest from 'react-canvas-nest'

import itemsMenu from './menu'
import Loading from './components/Loading'
import {AppContext, AppContextType} from '@/utils/common'
import {getQueryObject, typeObj} from "@/utils";
import { appStores } from '@/stores';

const {Header, Content, Footer} = Layout;

const App: React.FC = () => {
  const navigate = useNavigate()
  const CommonStore = appStores()['CommonStore'];
  const [current, setCurrent] = useState('home');

  const [tagColor] = useState<Required<AppContextType>['tagColor']>(['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'blue', 'purple'])

  const getTagColor = (): string => {
    return tagColor[Math.floor(Math.random() * tagColor.length)]
  }

  const [appContextDate, setAppContextDate] = useState<AppContextType>({
    getTagColor,
    tagColor
  });

  const onClick = (e: any) => {
    // @ts-ignore
    window.open({
        'npmlibrary': 'https://www.npmjs.com/~alun.zhang',
        'toollibrary': 'http://zylwt.com:9001',
        'libraryelement': 'http://zylwt.com:9000/#/zh-CN/component/installation',
        'libraryecharts': 'http://zylwt.com:9006/zh-CN/componentEcharts/z-polyline-chart.html',
        'vitenuxtdemo': 'http://zylwt.com:9007',
        'vitenuxtgitee': 'https://gitee.com/zhangyaolun/nuxt3-vite-template/tree/master',
      }[`${e.key}`]
    )
    // if (e.key === 'npmlibrary') {
    //   window.open('https://www.npmjs.com/~alun.zhang')
    // } else {
    //   setCurrent(e.key);
    //   navigate(`${e.key}`)
    // }
  };

  const getInit = () => {
    const { admin }: typeObj = getQueryObject()
    CommonStore.setAdmin(admin??'' !== '')
    CommonStore.getBlogInitCont()
  }

  useEffect(() => {
    getInit()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout className="layout">
      <Header>
        <Menu
          onClick={onClick}
          mode="horizontal"
          selectedKeys={[current]}
          items={itemsMenu}
        />
      </Header>
      <AppContext.Provider value={{...appContextDate}}>
        <Content style={{padding: '16px', minHeight: 'auto'}}>
          {/*<Breadcrumb style={{ margin: '16px 0' }}>*/}
          {/*  <Breadcrumb.Item>Home</Breadcrumb.Item>*/}
          {/*  <Breadcrumb.Item>List</Breadcrumb.Item>*/}
          {/*  <Breadcrumb.Item>App</Breadcrumb.Item>*/}
          {/*</Breadcrumb>*/}
          <div className="site-layout-content">
            <RouterGuard
              routers={routers}
              onRouterBefore={onRouteBefore}
              loading={<Loading/>}
            />
          </div>
          <ReactCanvasNest
            className='canvasNest'
            config={{
              pointColor: '0,0,0',
              lineColor: '0,0,0',
              pointOpacity: 0.1,
              pointR: 1.5,
              count: 100
            }}
            style={{ zIndex: -1, left: 0, top: 0, width: '100%', height: '100%'}}
          />
        </Content>
      </AppContext.Provider>
      <Footer style={{textAlign: 'center'}}>豫ICP备2022009726号</Footer>
    </Layout>
  )
}

export default App
