import { type, typeObj, typeArray } from "./type";

export * from './type';
export * from './localStorage';

//深拷贝
export const deepClone = (trg: typeObj | typeArray) => {
  let copyed_objs: typeArray = [];

  function _deepCopy(target: typeObj) {
    const tarType = type(target);
    if (tarType !== 'object' || !target || tarType as string === 'date') {
      return target;
    }
    for (let i = 0; i < copyed_objs.length; i++) {
      if (copyed_objs[i].target === target) {
        return copyed_objs[i].copyTarget;
      }
    }
    let obj: typeObj = {};
    if (tarType as string === 'array') {
      obj = []; // 处理target是数组的情况
    }
    copyed_objs.push({target: target, copyTarget: obj});
    Object.keys(target).forEach(key => {
      if (obj[key]) {
        return;
      }
      obj[key] = _deepCopy(target[key]);
    });
    return obj;
  }

  return _deepCopy(trg);
};

//随机数 用于dom节点唯一的id/class/ref生成
export const newMathRandom = () => Math.floor(Math.random() * (1 - 10000) + 10000);


//对象转数组
export const objToArr = (obj: typeObj, keyName = 'key', valueName = 'value') => {
  let arr = [];
  for (let key in obj) {
    arr.push({
      [keyName]: key,
      [valueName]: obj[key]
    });
  }
  return arr;
};

// 图像处理
export const getImg = (data: string | null) => {
  return data ? JSON.parse(data)[0].url : '';
};

// 获取url参数
export const getQueryObject = (url: string = ''): typeObj => {
  const _url = url ? window.location.href : url
  const search = _url.substring(_url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val);
    (obj as any)[name] = val
    return rs
  })
  return obj
};
