import {itemsMenuData} from "@/menu";
import {MetaRule, RouteObjectRule} from "./guard";

interface MetaRuleDefine extends MetaRule {
  auth?: false
}

interface RouterRule extends RouteObjectRule {
  meta: MetaRuleDefine
}

const routers: RouterRule[] = [
  {
    path: '/',
    meta: {
      title: itemsMenuData.home,
    },
    page: () => import("@/page/home")
  },
  {
    path: '/home',
    meta: {
      title: itemsMenuData.home,
    },
    page: () => import("@/page/home")
  },
  {
    path: `/articleDetail`,
    meta: {
      title: '文章详情',
    },
    page: () => import("@/page/articleDetail")
  },
  {
    path: '/notes',
    meta: {
      title: itemsMenuData.notes,
    },
    page: () => import("@/page/notes")
  },
  {
    path: '/libraryelement', // 组件库
    meta: {
      title: itemsMenuData.library,
    },
    page: () => import("@/page/library")
  },
  {
    path: '/libraryecharts', // 组件库
    meta: {
      title: itemsMenuData.library,
    },
    page: () => import("@/page/library")
  },
  {
    path: '/toollibrary', // 工具库
    meta: {
      title: itemsMenuData.toollibrary,
    },
    page: () => import("@/page/library")
  },
]

export default routers

