import type { MenuProps } from 'antd';
import { typeObj } from '@/utils';

const itemsMenu: MenuProps['items'] = [
  {
    key: 'home',
    label: '首页'
  },
  // {
  //   key: 'notes',
  //   label: '笔记'
  // },
  {
    key: 'library',
    label: '组件库',
    children: [
      {
        key: 'libraryelement',
        label: 'Element Ui 二次封装',
      },
      {
        key: 'libraryecharts',
        label: 'Echarts 二次封装',
      }
    ],
  },
  {
    key: 'toollibrary',
    label: '工具库'
  },
  {
    key: 'npmlibrary',
    label: 'NPM 仓库'
  },
  {
    key: 'vitenuxt',
    label: 'nuxt3 Demo',
    children: [
      {
        key: 'vitenuxtdemo',
        label: 'nuxt3 Demo线上',
      },
      {
        key: 'vitenuxtgitee',
        label: 'nuxt3 Demo源码地址',
      }
    ],
  },
]

const itemsMenuData: typeObj = {}
itemsMenu.forEach((v: any) => {
  itemsMenuData[v.key] = v.label
})

export {
  itemsMenuData
}

export default itemsMenu
