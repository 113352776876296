import {makeObservable, observable, action} from 'mobx';
import {getBlogInit} from "@/api";
import {typeObj} from "@/utils";

// interface AppCont {
//     article_cont: Number;
//     article_show_cont: Number;
// }

// type SetCommonStoreType = 'article_cont' | 'article_show_cont' | 'bannerList' | 'hotList' | 'tagList'

class CommonStore {
    is_admin: Boolean = false;
    article_cont: Number = 0;
    article_show_cont: Number = 0;
    bannerList: Array<any> = [];
    hotList: Array<any> = [];
    tagList: Array<any> = [];

    constructor() {
        makeObservable(this, {
            is_admin: observable,
            article_cont: observable,
            article_show_cont: observable,
            bannerList: observable,
            hotList: observable,
            tagList: observable,

            getBlogInitCont: action.bound
        });
    }

    setAdmin(data: boolean): void {
        this.is_admin = data
    }

    getBlogInitCont() {
        getBlogInit().then((res: typeObj) => {
            const {
                article_cont = 0,
                article_show_cont = 0,
                bannerList = [],
                hotList = [],
                tagList = [],
            } = res
            this.article_cont = article_cont
            this.article_show_cont = article_show_cont
            this.bannerList = bannerList
            this.hotList = hotList
            this.tagList = tagList
        })
    }

}

export default new CommonStore();
